<template>
	<a-spin :spinning="loading">
		<a-form-item :label="$t('table.applyInfo.financialLabel.loanType')" class="selet-branch">
			<a-select v-model="loanType" @change="handleChangeLoanType" class="select" style="width: 200px; display: inline-block">
				<a-select-option :value="item.value" v-for="(item, index) in loanTypeList" :key="index">{{ item.label }}</a-select-option>
			</a-select>
		</a-form-item>
		<a-form-item :label="$t('table.EmployeeManagement.Branchman.branchOffice')" class="selet-branch">
			<a-select v-model="branchOffice" @change="handleChangeBranchOffice" class="select" style="width: 200px; display: inline-block">
				<a-select-option :value="item.id" v-for="item in branchList" :key="item.id">{{ item.storeName }}</a-select-option>
			</a-select>
		</a-form-item>
		<!-- 审核流程设置 -->
		<div v-if="loanType === 'carloan' || loanType === 'emergency'">
			<a-card :title="$t('table.loanReview.Seeting.tabs.0') + $t('tipsMsg.tips1')" size="small">
				<p style="padding: 10px 0; font-weight: 600">{{ $t('table.loanReview.Seeting.tabs.3') }}</p>
				<a-table
					v-if="applyCheckSam.length > 0"
					class="thead-none"
					:columns="columns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="applyCheckSam"
					:pagination="false"
					bordered
				>
					<span slot="sort" slot-scope="text, obj, index">
						{{ index + 1 }}
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="editReviewer('applyCheckSam', 'apply_info', index, obj)">{{ $t(`page.edit`) }}</a>
					</span>
				</a-table>
				<p style="padding: 10px 0; font-weight: 600">{{ $t('table.loanReview.Seeting.tabs.4') }}</p>
				<a-table
					v-if="applyCheckChristine.length > 0"
					class="thead-none"
					:columns="columns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="applyCheckChristine"
					:pagination="false"
					bordered
				>
					<span slot="sort" slot-scope="text, obj, index">
						{{ applyCheckSam.length + index + 1 }}
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="editReviewer('applyCheckChristine', 'apply_info', index, obj)">{{ $t(`page.edit`) }}</a>
					</span>
				</a-table>
				<p style="padding: 10px 0; font-weight: 600">{{ $t('table.loanReview.Seeting.tabs.5') }}</p>
				<a-table
					v-if="applyCheckLarry.length > 0"
					class="thead-none"
					:columns="columns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="applyCheckLarry"
					:pagination="false"
					bordered
				>
					<span slot="sort" slot-scope="text, obj, index">
						{{ applyCheckSam.length + applyCheckChristine.length + index + 1 }}
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="editReviewer('applyCheckLarry', 'apply_info', index, obj)">{{ $t(`page.edit`) }}</a>
					</span>
				</a-table>
			</a-card>
			<a-card :title="$t('table.loanReview.Seeting.tabs.2') + $t('tipsMsg.tips2')" size="small" style="margin-top: 20px">
				<a-table :columns="columns3" size="small" :rowKey="(record, index) => index" :dataSource="applyInfoConfirmNode" :pagination="false" bordered>
					<span slot="sort" slot-scope="text, obj, index">
						{{ index + 1 }}
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="editReviewer('applyInfoConfirmNode', 'apply_info', index, obj)">{{ $t(`page.edit`) }}</a>
					</span>
				</a-table>
			</a-card>
			<a-card :title="$t('table.loanReview.Seeting.tabs.1')" size="small" style="margin-top: 20px">
				<a-button slot="extra" @click="selectReviewer('disburseInfoCheckNodeList', 'disburse_info')" type="primary">{{ $t('page.add') }}</a-button>
				<a-table
					:columns="columns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="disburseInfoCheckNodeList"
					:pagination="false"
					bordered
				>
					<span slot="sort" slot-scope="text, obj, index">
						{{ index + 1 }}
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="editReviewer('disburseInfoCheckNodeList', 'disburse_info', index, obj)">{{ $t(`page.edit`) }}</a
						><a-divider type="vertical" />
						<a @click="editReviewer('disburseInfoCheckNodeList', 'disburse_info', index)">{{ $t(`page.add`) }}</a>
						<a-divider type="vertical" />
						<a @click="DeleteChecker(obj.id, 'disburseInfoCheckNodeList', index)">{{ $t(`page.delete`) }}</a>
					</span>
				</a-table>
			</a-card>
		</div>
		<div v-else>
			<a-card :title="$t('table.loanReview.Seeting.tabs.0') + $t('tipsMsg.tips1')" size="small">
				<a-button slot="extra" @click="selectReviewer('ipfInfoCheckNodeList', 'ipf_info')" type="primary">{{ $t('page.add') }}</a-button>
				<a-table :columns="columns" size="small" :rowKey="(record, index) => index" :dataSource="ipfInfoCheckNodeList" :pagination="false" bordered>
					<span slot="sort" slot-scope="text, obj, index">
						{{ index + 1 }}
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="editReviewer('ipfInfoCheckNodeList', 'ipf_info', index, obj)">{{ $t(`page.edit`) }}</a>
						<a-divider type="vertical" />
						<a @click="editReviewer('ipfInfoCheckNodeList', 'ipf_info', index)">{{ $t(`page.add`) }}</a>
						<a-divider type="vertical" />
						<a @click="DeleteChecker(obj.id, 'ipfInfoCheckNodeList', index)">{{ $t(`page.delete`) }}</a>
					</span>
				</a-table>
			</a-card>
			<a-card :title="$t('table.loanReview.Seeting.tabs.2') + $t('tipsMsg.tips2')" size="small" style="margin-top: 20px">
				<p style="padding: 10px 0; font-weight: 600">Only for IPF Renewal</p>
				<a-table :columns="columns3" size="small" :rowKey="(record, index) => index" :dataSource="ipfRenewalNode" :pagination="false" bordered>
					<span slot="sort" slot-scope="text, obj, index">
						{{ index + 1 }}
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="editReviewer('ipfRenewalNode', 'ipf_info', index, obj)">{{ $t(`page.edit`) }}</a>
					</span>
				</a-table>
			</a-card>
		</div>
		<a-button @click="SaveData" type="primary" style="margin-top: 20px">{{ $t('operate.save') }}</a-button>
		<a-modal v-model="modal.show" :destroyOnClose="true" title="" width="80%" ok-text="ok" cancel-text="cancel" @ok="submitFrom">
			<a-form-model layout="inline">
				<a-form-model-item>
					<a-input v-model="modal.userId"></a-input>
				</a-form-model-item>
				<a-form-model-item>
					<a-button type="primary" @click="searchUserList" v-t.preserve="'operate.search'"></a-button>
				</a-form-model-item>
			</a-form-model>
			<a-table
				:columns="columns2"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="userList"
				:pagination="false"
				:row-selection="{
					fixed: true,
					columnWidth: 50,
					onChange: (k, r) => {
						;(this.selectedRowKeys = k), (this.selectedRows = r)
					},
					selectedRowKeys: selectedRowKeys
				}"
				bordered
			></a-table>
		</a-modal>
	</a-spin>
</template>
<script>
import { apiBranchList } from '@/api/branch'
import { apiApplyUserList, apiDisburseUserList } from '@/api/role'
import { apiGetChecker, apiCheckSetting, apiCheckerDel } from '@/api/check'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
export default {
	data() {
		return {
			// applyInfoCheckNodeList: [],
			applyInfoConfirmNode: [],
			disburseInfoCheckNodeList: [],
			applyCheckChristine: [],
			applyCheckLarry: [],
			applyCheckSam: [],
			// greaterThan100wNode: [],
			ipfInfoCheckNodeList: [],
			ipfRenewalNode: [],
			modal: {
				type: '',
				parentNodeId: '',
				modifyIndex: 0,
				userId: '',
				checkers: [],
				modify: false,
				show: false
			},
			columns2: [
				{
					// username
					title: this.$t('table.Permission.User.username'),
					dataIndex: 'username',
					align: 'center'
				},
				{
					// displayName
					title: this.$t('table.Permission.User.displayName'),
					dataIndex: 'displayName',
					align: 'center'
				},
				{
					// mobile
					title: this.$t('table.Permission.User.mobile'),
					dataIndex: 'mobile',
					align: 'center'
				}
			],
			userList: [],
			selectedRowKeys: [],
			selectedRows: [],
			AllUserList: [],
			loading: false,
			branchList: [],
			branchOffice: '',
			loanTypeList: [],
			loanType: ''
		}
	},
	computed: {
		...mapGetters(['setting']),
		isLang() {
			return this.setting.curLanguage.locale === 'en'
		},
		columns() {
			return [
				{
					// 顺序
					title: this.$t('table.loanReview.Seeting.cloums.order'),
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'sort' }
				},
				{
					// 审核人
					title: this.$t('table.loanReview.Seeting.cloums.reviewer'),
					align: 'center',
					customRender: (v, o) => {
						let checkers = o.checkers
						let arr = []
						checkers.map((item) => {
							let result = this.AllUserList[o.parentNodeId === 'apply_info' || 'ipf_info' ? 0 : 1].find((ele) => ele.id == item.userId)
							if (result) {
								arr.push(result.username)
							}
						})
						return arr.join(',')
					}
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 200
				}
			]
		},
		columns3() {
			let confirmorColumns = cloneDeep(this.columns)
			confirmorColumns[1].title = this.$t('table.loanReview.Seeting.cloums.confirmor')
			return confirmorColumns
		}
	},
	methods: {
		apiGetChecker() {
			apiGetChecker({
				officeId: this.branchOffice,
				loanType: this.loanType
			})
				.then((res2) => {
					// this.applyInfoCheckNodeList = res2.applyInfoCheckNodeList ? res2.applyInfoCheckNodeList : [{ checkers: [] }]
					this.disburseInfoCheckNodeList = res2.disburseInfoCheckNodeList ? res2.disburseInfoCheckNodeList : [{ checkers: [] }]
					this.applyInfoConfirmNode = res2.applyInfoConfirmNode ? [res2.applyInfoConfirmNode] : [{ checkers: [] }]
					this.applyCheckChristine = res2.applyCheckChristine ? res2.applyCheckChristine : [{ checkers: [] }]
					this.applyCheckLarry = res2.applyCheckLarry ? res2.applyCheckLarry : [{ checkers: [] }]
					this.applyCheckSam = res2.applyCheckSam ? res2.applyCheckSam : [{ checkers: [] }]
					// this.greaterThan100wNode = res2.greaterThan100wNode ? [res2.greaterThan100wNode] : [{ checkers: [] }] [{ checkers: [], parentNodeId: 'ipf_info' }]
					this.ipfInfoCheckNodeList = res2.ipfInfoCheckNodeList ? res2.ipfInfoCheckNodeList : null
					this.ipfRenewalNode = res2.ipfRenewalNode ? [res2.ipfRenewalNode] : null
					this.loading = false
				})
				.catch((err) => {
					this.loading = false
				})
		},
		handleChangeLoanType(val) {
			this.loanType = val
			this.loading = true
			this.apiGetChecker()
		},
		handleChangeBranchOffice(val) {
			this.branchOffice = val
			this.loading = true
			this.apiGetChecker()
		},
		// 获取用户列表
		async searchUserList() {
			this.modal.parentNodeId === 'apply_info' || 'ipf_info'
				? (this.userList = await apiApplyUserList({
						username: this.modal.userId
				  }))
				: (this.userList = await apiDisburseUserList({
						username: this.modal.userId
				  }))
		},
		async selectReviewer(type, parentNodeId) {
			this.modal.userId = ''
			this.selectedRows = []
			this.selectedRowKeys = []
			this.modal.type = type
			this.modal.parentNodeId === parentNodeId
			this.modal.modifyIndex = -1
			this.modal.modify = false
			this.userList = this.AllUserList[parentNodeId === 'apply_info' || 'ipf_info' ? 0 : 1]
			this.modal.show = true
		},
		editReviewer(type, parentNodeId, modifyIndex, updateObj) {
			this.modal.userId = ''
			this.selectedRows = []
			this.selectedRowKeys = []
			this.modal.type = type
			this.modal.parentNodeId === parentNodeId
			this.userList = this.AllUserList[parentNodeId === 'apply_info' || 'ipf_info' ? 0 : 1]
			this.modal.modifyIndex = modifyIndex
			this.modal.modify = false
			if (updateObj) {
				updateObj.checkers.map((item) => {
					let index = this.userList.findIndex((ele) => ele.id == item.userId)
					if (index > -1) {
						this.selectedRows.push(this.userList[index])
						this.selectedRowKeys.push(index)
					}
				})
				this.modal.modify = true
			}
			this.modal.show = true
		},
		// 删除审核节点设置
		DeleteChecker(id, type, idx) {
			this.$confirm({
				title: 'Please check again to make sure',
				content: '',
				okText: 'Yes',
				okType: 'danger',
				cancelText: 'No',
				onOk: () => {
					if (id) {
						apiCheckerDel(this.branchOffice, this.loanType, id).then(() => {
							this.$message.success('delete success')
							this.apiGetChecker()
						})
					} else {
						this[type].splice(idx, 1)
					}
				},
				onCancel() {}
			})
		},
		// 提交表单
		submitFrom() {
			if (this.selectedRows.length > 0) {
				let obj = { checkers: [], sort: 0, parentNodeId: this.modal.parentNodeId }
				this.selectedRows.map((item) => {
					obj.checkers.push({ userId: item.id })
				})
				this.modal.modifyIndex > -1
					? this.modal.modify
						? (this[this.modal.type][this.modal.modifyIndex].checkers = obj.checkers)
						: this[this.modal.type].splice(this.modal.modifyIndex + 1, 0, obj)
					: this[this.modal.type].push(obj)
				this.modal.show = false
			}
		},
		// 提交保存数据
		SaveData() {
			let params = {
				applyCheckSam: [],
				applyCheckChristine: [],
				applyCheckLarry: [],
				disburseInfoCheckNodeList: [],
				applyInfoConfirmNode: {},
				ipfInfoCheckNodeList: [],
				ipfRenewalNode: {}
			}
			this.applyCheckSam.map((item, idx) => {
				params.applyCheckSam.push({
					parentNodeId: item.parentNodeId,
					checkers: item.checkers,
					sort: idx + 2,
					id: item.id ? item.id : ''
				})
			})
			this.applyCheckChristine.map((item, idx) => {
				params.applyCheckChristine.push({
					parentNodeId: item.parentNodeId,
					checkers: item.checkers,
					sort: idx + 2,
					id: item.id ? item.id : ''
				})
			})
			this.applyCheckLarry.map((item, idx) => {
				params.applyCheckLarry.push({
					parentNodeId: item.parentNodeId,
					checkers: item.checkers,
					sort: idx + 2,
					id: item.id ? item.id : ''
				})
			})
			this.disburseInfoCheckNodeList.map((item, idx) => {
				params.disburseInfoCheckNodeList.push({
					parentNodeId: item.parentNodeId,
					checkers: item.checkers,
					sort: idx + 2,
					id: item.id ? item.id : ''
				})
			})
			if (this.ipfInfoCheckNodeList) {
				this.ipfInfoCheckNodeList.map((item, idx) => {
					params.ipfInfoCheckNodeList.push({
						parentNodeId: item.parentNodeId,
						checkers: item.checkers,
						sort: idx + 2,
						id: item.id ? item.id : ''
					})
				})
			}
			params.applyInfoConfirmNode = {
				parentNodeId: this.applyInfoConfirmNode[0].parentNodeId,
				checkers: this.applyInfoConfirmNode[0].checkers,
				sort: this.applyInfoConfirmNode[0].sort,
				id: this.applyInfoConfirmNode[0].id,
				childNodes: this.applyInfoConfirmNode[0].childNodes
			}
			if (this.ipfRenewalNode) {
				params.ipfRenewalNode = this.ipfRenewalNode[0]
					? {
							parentNodeId: this.ipfRenewalNode[0].parentNodeId,
							checkers: this.ipfRenewalNode[0].checkers,
							sort: this.ipfRenewalNode[0].sort,
							id: this.ipfRenewalNode[0].id,
							childNodes: this.ipfRenewalNode[0].childNodes
					  }
					: {}
			}
			// params.ipfRenewalNode = this.ipfRenewalNode[0]
			// 	? {
			// 			parentNodeId: this.ipfRenewalNode[0].parentNodeId,
			// 			checkers: this.ipfRenewalNode[0].checkers,
			// 			sort: this.ipfRenewalNode[0].sort,
			// 			id: this.ipfRenewalNode[0].id,
			// 			childNodes: this.ipfRenewalNode[0].childNodes
			// 	  }
			// 	: {}
			params.officeId = this.branchOffice
			params.loanType = this.loanType
			this.loading = true
			apiCheckSetting(params)
				.then(() => {
					this.$message.success('success')
					this.apiGetChecker()
				})
				.finally(() => {
					this.loading = false
				})
		}
	},
	created() {
		this.$nextTick(() => {
			Promise.all([
				apiApplyUserList({
					username: this.modal.userId
				}),
				apiDisburseUserList({
					username: this.modal.userId
				}),
				apiBranchList()
			]).then((res) => {
				this.branchList = res[2]
				this.branchOffice = this.branchList[0].id
				this.loanTypeList = [
					{ label: 'Car Loan', value: 'carloan' },
					{ label: 'IPF/Insurance Renewal', value: 'ipf_renewal' },
					{ label: 'Emergency/ Cash Pap', value: 'emergency' }
				]
				this.loanType = this.loanTypeList[0].value
				this.AllUserList = res
				this.loading = true
				this.apiGetChecker()
			})
		})
	}
}
</script>
<style lang="less">
.selet-branch {
	display: inline-block;
	margin-right: 30px;
	.ant-col {
		display: inline-block;
	}
	.select {
		width: 200px;
		display: inline-block;
		margin-left: 20px;
	}
}
// .thead-none {
// 	.ant-table-thead {
// 		display: none;
// 	}
// }
</style>
